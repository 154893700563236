import React from "react"
import styled from "styled-components"
import { graphql, Link } from 'gatsby'
import SEO from "../components/seo"
import GridItem from "../components/grid-item"
import Thumbnail from "../components/thumbnail"
import { BodyCopy } from "../components/styles/textStyles"

const ExitButton = styled(Link)`
  position: absolute;
  cursor: pointer;
  box-sizing: content-box;
  left: -50px;
  top: 0;
  width: 27px;
  height: 27px;

  @media (max-width: 1439px) {
    display: none;
  }

  @media (min-width: 1440px) {
    left: 58px;
  }

  &.mobile-only {
    @media (max-width: 1439px) {
      top: -5px;
      display: block;
    }

    @media (min-width: 1440px) {
      display: none;
    }
  }

  div {
    position: relative;
    height: 100%;
    width: 100%;
  }

  span {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    transition: all .8s cubic-bezier(.39,.01,.32,.93);
    background-color: white;
    transform-origin: center;
  }

  span:first-child {
    transform: rotate(45deg);
  }

  span:last-child {
    transform: rotate(135deg);
  }
`

class ProfilePage extends React.Component {
  
  componentDidMount() {
    document.getElementById('site-container').scrollTo(0, 0);
  }

  render() {
    const page = this.props.data.text
    const thumbnail = this.props.data.thumbnail.childImageSharp.fluid
    return (
      <>
        <SEO title={page.frontmatter.title} />
        <GridItem start="3" end="4" tabletEnd="7">
          <ExitButton to="/our-team" className="mobile-only">
            <div>
              <span></span>
              <span></span>
            </div>
          </ExitButton>
          <Thumbnail 
            name={page.frontmatter.title} 
            jobTitle={page.frontmatter.jobTitle} 
            image={thumbnail} 
            url={page.fields.slug} 
            email={page.frontmatter.email}
            profileView={true} 
          />
        </GridItem>
        <GridItem start="4" end="7" tabletStart="3" tabletEnd="7" insetContent="100px">
          <ExitButton to="/our-team">
            <div>
              <span></span>
              <span></span>
            </div>
          </ExitButton>
          <BodyCopy dangerouslySetInnerHTML={{ __html: page.html }} />
        </GridItem>
      </>
    )
  }
}


export const query = graphql`
  query($slug: String!, $thumbnail: String!) {
    text: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        jobTitle
        email
      }
    }
    thumbnail: file(name: { eq: $thumbnail }) {
      childImageSharp {
        fluid(maxWidth: 170, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ProfilePage
